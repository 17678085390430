<template>
  <section class="bg-gray10 bg-opacity-50">
    <div class="container mx-auto px-4">
      <div
        class="flex flex-col-reverse
          md:flex-row
          pt-32
          pb-16
          md:pb-16
          px-6
          md:px-0"
      >
        <div
          class="flex items-center px-2 md:px-0 text-left lg:w-2/5"
        >
          <div>
            <h2 class="text-4xl font-semibold md:text-7xl">
              Your Management Solution
            </h2>
            <div class="h-2 md:w-16 bg-brandBlue mt-5 mb-4"></div>
            <p>
              Maven is one of the leading Facility Management companies in India. Providing 100% tailored solutions for organisations across the country.
            </p>
          </div>
        </div>
        <div class="lg:w-3/5">
          <div class="h-full md:mb-0 mb-10">
            <img
              src="@/assets/images/backgorund/services-hero.png"
              class="max-w-full assets"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container mx-auto px-5 py-10 md:py-32" id="service">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl font-medium">Service Offerings</div>
          <div class="h-0.5 hidden md:flex w-96 bg-black mt-6 mb-4"></div>
        </div>
        <div
          class="
            md:w-1/2 flex-shrink-0
            text-lg
            md:text-2xl mt-10 px-0
            md:mt-16
          " >
        <p>
        Bringing you the finest facility management solutions for a better and healthier working environment with our expertise and experience.
        </p>
        </div>
      </div>
    </div>
  </section>
  <section>
   <img src="@/assets/images/backgorund/lightbulbs-blue-background.png" class="w-full h-auto assets" />
  </section>
  <section class="bg-brandBlue">
    <div class="container bg-brandBlue text-white mx-auto py-20 md:py-32" id="techsolution">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2 px-5 flex-shrink-0">
          <div class="text-2xl md:text-5xl font-medium">Technical Solutions</div>
          <div class="h-0.5 hidden md:flex bg-white mt-6 mb-4"></div>
          <p class="mt-7">
          We offer a whole range of Electromechanical, Maintenance and Repair solutions. Our engineers and technicians are well equipped with skills and tools to ensure that your needs are met. We use the latest technology and prevention methodology to help you secure and maintain your assets for a longer duration and mitigate the risk of failure.
          </p>
          <p class="mt-3">
          We provide regular comprehensive system checkups, performance evaluation and tech updates to optimise and ensure there is no productivity loss.
          </p>
          <p class="mt-3">
           Our top-notch engineers make sure our solutions are reliable and in strict adherence to maintenance protocols in data centres, labs, manufacturing spaces, and other critical facilities.
          </p>
        </div>
        <div
          class="
            px-6 md:px-20
            md:w-1/2
            flex-shrink-0
            mt-20
            md:mt-20
          "
        >
        <p class="text-lg md:text-2xl">
          Our Electromechanical services include :
       </p>
        <p class="mt-2 ">
          <ul class="md:list-disc text-left md:pl-5">
            <li>HVAC Operations and Maintenance </li>
            <li>Electrical Operations and Maintenance</li>
            <li> Plumbing </li>
            <li> STP / WTP Maintenance</li>
            <li> Air-conditioning </li>
            <li> Operations & Maintenance of all installations </li>
            <li> Power Management </li>
            <li> General Electrical Maintenance</li>
            <li> Power & UPS monitoring</li>
            <li> Breakdown Maintenance </li>
            <li> Pump operators </li>
            <li> Lift Technicians / Operators </li>
            <li> Ventilation System </li>
            <li> Fire Protection & Detection Systems </li>
            <li> Security Control Services </li>
            <li> Centre Management Services</li>
          </ul>
        </p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container mx-auto px-5 py-10 md:py-24" id="softsolution">
      <div class="flex text-left md:flex-row mt-12">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl pb-10">Soft Service <br/> Solutions</div>
        </div>
      </div>
      <div class="border-t-2 border-black -mx-5 border-solid">
        <div
          class="
            grid grid-cols-1
            sm:grid-cols-1
            md:grid-cols-2
            xl:grid-cols-2
            pt-10
          ">
          <div class="flex flex-col px-5 py-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h3 class="flex flex-col font-bold text-darkBlue text-xl mr-4">
                  01
                </h3>
                <h3 class="font-medium text-xl">Housekeeping Service</h3>
              </div>
              <p class="text-gray-500 text-left py-5">
                At Maven we employ a combination of Mechanised + Manpower led housekeeping techniques. Our solution architects curate customized and cost-effective housekeeping and maintenance strategies to help you keep your vicinity hygienic, clean and green.
                <br/><br/>
                We use sustainable and organic products to consistently achieve results while caring for the environment. Our efficient and well-trained staff is prepared to deliver the quality services that you are looking for.
                <br/><br/>
                Our housekeeping services also include Grounds Maintenance &amp; Landscaping Services to ensure outdoor areas are as well-kept as interiors.
              </p>
            </div>
          </div>
          <div class="flex flex-col px-5 py-5">
            <div class="flex-1">
              <div class="w-80 h-full md:ml-10">
                <img
                  src="@/assets/images/backgorund/soft-solution.png"
                  class="max-w-full assets"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col px-5 py-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h3 class="flex flex-col font-bold text-darkBlue text-xl mr-4">
                  02
                </h3>
                <h3 class=" font-medium text-xl">Pantry Management</h3>
              </div>
              <p class="text-gray-500 text-left py-5">With our pantry management services, we aim at reducing clutter, wastage of food while properly maintaining storage and hygiene in the area.<br><br>
              We prepare and plan comprehensive reports on monthly consumption, requisition for pantry items, and take care of the inventory.<br><br>
              Our pantry management staff is highly trained to manage, supervise and organize your pantry needs. At Maven we make sure your employees are savouring food and producing results with our organised pantry services.</p>
            </div>
          </div>
          <div class="flex flex-col px-5 py-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h3 class="flex flex-col font-bold text-darkBlue text-xl mr-4">
                  03
                </h3>
                <h3 class="font-medium text-xl">Waste Management</h3>
              </div>
              <p class="text-gray-500 text-left py-5">Waste Management is the biggest challenge of the 21st century due to increasing population and waste generation.<br><br>
              Proper Waste management planning curtails the effect of waste on the environment and health and plays a crucial role in delivering sustainable development and contributing to CSR activities.<br><br> 
              At Maven we offer a comprehensive range of waste management and recycling solutions to different industries types and manage all kinds of wet/dry biodegradable waste, e-waste and recyclable waste.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-darkBlue">
    <div class="container bg-darkBlue text-white mx-auto px-5 py-16 md:py-32" id="pest">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl font-medium">Pest Management Solutions</div>
          <div class="h-0.5 hidden md:flex w-full bg-white mt-6 mb-4"></div>
          <p class="mt-7">
          Pests can cause severe damage to your facility and create a range of problems like skin allergies, asthma and an unsuitable environment for your employees and visitors.
          </p>
          <p class="mt-3">
          Therefore, an evaluated pest management plan is vital to save furniture, carpets, clothing, and prevent expenses related to repairs and health conditions.
          </p>
          <p class="mt-3">
          At Maven we seek to eliminate the population of insects that are considered detrimental to humans, your facility and the environment by using green methods to ensure quality, health and safety regulations.
          </p>
        </div>
        <div
          class="
            md:px-20
            md:w-1/2
            flex-shrink-0
            mt-20
            md:mt-20
          "
        >
          <p class="mt-8 text-lg md:text-2xl">Our pest management services include :</p>
          <p class="mt-2 ">
            <ul class="md:list-disc text-left md:pl-5">
              <li>General Pest Control</li>
              <li>Mosquito / Wasps / Bees Control </li>
              <li>Rodent / Bird Control </li>
              <li>Anti-Termite Treatment </li> 
              <li>Snake Control </li>
              <li>General Disinfection Services </li>
              <li>Fumigation</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="relative overflow-hidden">
      <div class="container mx-auto px-4">
        <div class="flex flex-col md:flex-row md:pt-28 pb-20 md:pb-28" id="security">
          <div class="md:w-1/2 px-5 md:px-20 my-10 md:my-0">
            <img
                src="@/assets/images/backgorund/security.png"
                class="max-w-full assets"
              />
          </div>
          <div
            class="flex px-2 md:px-0 text-left lg:w-1/2 md:pr-20"
          >
            <div>
              <div class="text-2xl md:text-5xl font-medium">Security Solutions</div>
              <div class="h-0.5 hidden md:flex bg-black mt-6 mb-4"></div>
              <p class=" mt-10">
                We have one of the best-trained security guards in the industry with keen observation skills and a wide range of security solutions and access control systems. We offer customized solutions to schools, government buildings, public centres, retail stores, commercial and residential buildings, hotels, and restaurants.
              </p>
              <ul class="md:list-disc text-left md:pl-5 mt-10">
                <li>24/7 Control Room Facility</li>
                <li>Extensive Communication Network For All Staff</li>
                <li>Regular Inspection And Patrolling At Night</li>
                <li>Quick And Efficient Response During A Crisis</li>
                <li>Regular Feedback Collection</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="overflow-hidden h-52 md:h-96">
   <img src="@/assets/images/backgorund/man-touching-business.png" class="w-full object-cover h-full assets" />
  </section>
  <section class="bg-black overflow-hidden">
    <div class="container text-white mx-auto px-5 py-10" id="specialservices">
      <div class="flex text-left md:flex-row mt-20">
        <div class="flex flex-col items-left md:w-1/">
          <div class="text-2xl md:text-5xl font-medium">Specialised Services Solutions</div>
        </div>
      </div>
      <div class="border-t border-white pt-6 mt-10">
         <div
          class="
            grid grid-cols-1
            sm:grid-cols-1
            md:grid-cols-2
            xl:grid-cols-2 -mx-16
            "
            >
            <div v-for="(data,index) in solutions" :key="index" class="flex flex-col px-16 pt-10">
              <div class="flex-1">
                <div class="flex items-center">
                  <h3 class="flex flex-col font-bold text-yellow text-xl mr-4">
                    0{{index+1}}
                  </h3>
                  <h3 class="font-bold text-xl">{{data.title}}</h3>
                </div>
                <p class="text-gray-500 text-left py-5">{{data.description}}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
  <!-- <section>
    <div class="container mx-auto md:px-32 py-32" id="clients">
      <div class="flex flex-col items-center">
        <div class="text-2xl md:text-5xl font-medium px-6">What Our Clients Say</div>
        <div class="h-2 md:w-16 bg-brandBlue mt-5 mb-4"></div>
      </div>
      <div class="flex md:my-10 px-5 md:px-0 flex-col md:flex-row">
        <div class="flex-1 md:w-56 bg-brandBlue text-white py-10 md:mx-10">
          <div class="items-center">
            <div class="text-center">
                <img class="mx-auto p-5" src="@/assets/images/quote-icon.svg"/>
            </div>
            <div class="px-10 md:px-20 text-center">
              <p class="text-gray-500 text-sm mt-2">
                Remarkable customer service and team support!. We are very glad to work with Maven. They provided us with timely support, highly trained staff and one of the best teams to manage our facility. We look forward to many more years of partnership.
              </p>
              <h2 class="mt-4 text-center">
                <div class="h-2 md:w-16 mx-auto text-center bg-yellow mt-5 mb-4"></div>
              </h2>
              <h2 class="mt-7 text-center">
                XYZ, HUmann resources
              </h2>
            </div>
          </div>
        </div>
        <div class="flex-1 md:w-56 bg-brandBlue mt-10 md:mt-0 text-white py-10 md:mx-10">
          <div class="items-center">
            <div class="text-center">
              <img class="mx-auto p-5" src="@/assets/images/quote-icon.svg"/> 
            </div>
              <div class="px-10 md:px-20 text-center">
              <p class="text-gray-500 text-sm mt-2">
                For organisations, proper facility management is very critical to retain talent, prospective visitors and clients. Maven helped us understand our industry requirements and supported our operations with ease, comfort and cost-effective ways. Thank you Maven for your support.
              </p>
              <h2 class="mt-4 text-center">
                <div class="h-2 md:w-16 mx-auto text-center bg-yellow mt-5 mb-4"></div>
              </h2>
              <h2 class="mt-7 text-center">
                XYZ, HUmann resources
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->


</template>

<script>
export default {
  name: "Services",
  data() {
    return {
      solutions: [
        {
          title: "Help Desk Management",
          description:
            "Good customer service helps to retain customers and recoup consumer acquisition costs while extracting more value from them. At Maven we offer tailored Help Desk Management solutions to provide your business with customer-centric service and automate and organize daily workflow to solve all the problems with ease, reliability and accuracy.",
        },
        {
          title: "Vendor Management",
          description:
            "Through our vendor management services, we aim at facilitating organisations with best-in-class vendors to provide a perfect blend of self-performed and contract services to meet quality and cost goals. Our strategic sourcing facilitates and maintains relationships between your organization and vendors, creating high standard quality while sharing goals and establishing priorities.",
        },
        {
          title: "Staffing And Payroll Management",
          description:
            "At Maven we offer highly secured, flexible and customised payroll management solutions. Our management system ensures that we are in compliance with minimum wages, PF, ESIC, and labour laws, to avoid any legal complications. Through our tailored staffing management solutions, we provide seasoned recruitment teams that can quickly adapt, understand processes and simplify your operations.",
        },
        {
          title: "Façade Cleaning Services",
          description:
            "At Maven we offer safe, sustainable and customized Façade cleaning services. Our undivided priority is always on productivity, safety, effective handling, making it easy to access a hard-to-reach area. Our team is highly experienced and competent to create long-term value retention of the building and enhanced life of the façade.",
        },
        {
          title: "Horticulture",
          description:
            "Horticulture services include gardening responsibilities, such as planting and maintaining shrubs, trees, and lawn areas to beautify and enhance the outlook of your facility. Our gardening team specialises in mowing, pruning, and fertilizing, as well as clutter disposal services, like removing brush and unwanted layers of debris.",
        },
        {
          title: "Carpet Shampooing",
          description:
            "Carpet shampooing is required to remove thick dirt, mud, grime and other unwanted substances. We offer specially formulated carpet shampooing services to remove pet stains, odours, food stains, musty smells and elongate the life of the fabric.",
        },
        {
          title: "Project Cleaning",
          description:
            "We offer one-time comprehensive project cleaning services that help promote healthy work environments and keep your buildings tidy and well maintained.",
        },
      ],
    };
  },
};
</script>

<style>
</style>
