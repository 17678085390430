<template>
  <div class="container mx-auto my-20 px-6 py-6">
    <div class="mt-10">
      <h1 class="text-darkBlue-600 font-bold font-sans mb-4 text-xl">
        Our Terms &amp; Policy
      </h1>
      <div>
        At Maven we employ a combination of Mechanised + Manpower led
        housekeeping techniques. Our solution architects curate customized and
        cost-effective housekeeping and maintenance strategies to help you keep
        your vicinity hygienic, clean and green. We use sustainable and organic
        products to consistently achieve results while caring for the
        environment. Our efficient and well-trained staff is prepared to deliver
        the quality services that you are looking for. Our housekeeping services
        also include Grounds Maintenance Landscaping Services to ensure outdoor
        areas are as well-kept as interiors.
      </div>
    </div>
    <div class="mt-10">
      <h1 class="text-darkBlue-600 font-bold font-sans mb-4 text-xl">
        Our Terms &amp; Policy
      </h1>
      <div>
        At Maven we employ a combination of Mechanised + Manpower led
        housekeeping techniques. Our solution architects curate customized and
        cost-effective housekeeping and maintenance strategies to help you keep
        your vicinity hygienic, clean and green. We use sustainable and organic
        products to consistently achieve results while caring for the
        environment. Our efficient and well-trained staff is prepared to deliver
        the quality services that you are looking for. Our housekeeping services
        also include Grounds Maintenance Landscaping Services to ensure outdoor
        areas are as well-kept as interiors.
      </div>
    </div>
    <div class="mt-10">
      <h1 class="text-darkBlue-600 font-bold font-sans mb-4 text-xl">
        Our Terms &amp; Policy
      </h1>
      <div>
        At Maven we employ a combination of Mechanised + Manpower led
        housekeeping techniques. Our solution architects curate customized and
        cost-effective housekeeping and maintenance strategies to help you keep
        your vicinity hygienic, clean and green. We use sustainable and organic
        products to consistently achieve results while caring for the
        environment. Our efficient and well-trained staff is prepared to deliver
        the quality services that you are looking for. Our housekeeping services
        also include Grounds Maintenance Landscaping Services to ensure outdoor
        areas are as well-kept as interiors.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tnc",
  components: {},
};
</script>