<template>
  <section class="bg-black">
    <div class="container mx-auto px-5">
      <div
        class="
          flex flex-col-reverse
          md:flex-row
          pt-32
          pb-16
          md:pb-16
          px-6
          md:px-0
        "
      >
        <div
          class="flex items-center text-center lg:text-left lg:w-2/5 md:pr-20"
        >
          <div>
            <h2 class="text-4xl text-white font-semibold md:text-7xl">
              Customised Solutions For Clients
            </h2>
            <div
              class="h-2 md:w-16 bg-brandBlue mt-5 mb-4 hidden md:block"
            ></div>
          </div>
        </div>
        <div class="lg:w-3/5">
          <div class="h-full md:mb-0 mb-10 assets">
            <img
              src="@/assets/images/backgorund/about-hero.png"
              class="max-w-full"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-brandBlue overflow-hidden">
    <div class="container bg-brandBlue text-white mx-auto px-5 pb-10 md:pb-32">
      <div class="flex flex-col text-left md:flex-row pt-10 md:pt-32" id="facility">
        <div class="flex flex-col items-left md:w-1/2 flex-shrink-0">
          <div class="text-2xl md:text-5xl font-medium">
            Maven: Your <br class="hidden md:flex"> Facility Expert
          </div>
          <div class="h-0.5 hidden md:flex w-96 bg-white mt-6 mb-4"></div>
          <p class="mt-7">
            We are facility management experts based in India. With our
            experience and expertise, we aim to deliver the finest facility
            management solutions to create a positive ambience for your
            employees and visitors.
          </p>
          <p class="mt-2">
            Our Customer-Centric solutions are powered by our experience +
            expertise approach, a unique combination of best industry practices
            with specialized solutions. Our solution-driven methodology delivers
            increased productivity and provides the right atmosphere for
            employees, visitors, and potential clients.
          </p>
          <p class="mt-2">
            We believe in contributing towards a sustainable environment and
            creating excellence through our capabilities by delivering tailored
            management services to optimize and enhance facilities.
          </p>
        </div>
        <div
          class="
            md:px-20 md:w-1/2
            flex-shrink-0
            text-lg
            md:text-2xl
            mt-20
            md:mt-20
          "
        >
          <p class="mt-7">
            Our staff and employees are highly skilled professionals with an
            understanding of a whole range of industries.
          </p>
        </div>
      </div>

      <div class="flex text-center md:text-left md:flex-row pt-10 md:pt-32" id="edge">
        <div class="flex flex-col items-left md:w-1/">
          <div class="text-2xl md:text-5xl font-medium">Our Edge</div>
        </div>
      </div>
      <div class="border-t-2 mt-10 border-white">
        <div
          class="
            grid grid-cols-1
            sm:grid-cols-1
            md:grid-cols-2
            xl:grid-cols-3
            mt-6
          "
        >
          <div class="flex w-full flex-col md:px-10 lg:pl-0 pt-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h2 class="mt-4 text-2xl">Always Equipped</h2>
              </div>
              <p class="text-gray-500 mt-5">
                We are always up-to-date with the current trends and best
                practices in the Facility Management space.
              </p>
            </div>
          </div>
          <div class="flex w-full flex-col md:px-10 pt-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h2 class="mt-4 text-2xl">Fully Compliant</h2>
              </div>
              <p class="text-gray-500 mt-5">
                We strictly adhere to statutory compliance norms without
                compromising the quality of our services.
              </p>
            </div>
          </div>
          <div class="flex w-full flex-col md:px-10 pt-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h2 class="mt-4 text-2xl">Trained Workforce</h2>
              </div>
              <p class="text-gray-500 mt-5">
                Our employees/staff go through rigorous training before they are
                assigned to any project.
              </p>
            </div>
          </div>
          <div class="flex w-full flex-col md:px-10 lg:pl-0 pt-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h2 class="mt-4 text-2xl">Sustainable Approach</h2>
              </div>
              <p class="text-gray-500 mt-5">
                We believe in a better tomorrow therefore, we use sustainable
                technology, tools & equipment in the delivery of soft services.
              </p>
            </div>
          </div>
          <div class="flex w-full flex-col md:px-10 pt-5">
            <div class="flex-1">
              <div class="flex items-center">
                <h2 class="mt-4 text-2xl">Customized Solutions</h2>
              </div>
              <p class="text-gray-500 mt-5">
                We offer tailored and innovative solutions to reach beyond your
                expectations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="overflow-hidden h-52 md:h-96">
    <img src="@/assets/images/businessman.png" class="w-full object-cover h-full assets" />
  </section>

  <section class="bg-gray bg-opacity-50 overflow-hidden">
    <div class="container mx-auto px-5 pt-10 md:pt-32" id="mission">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl font-medium">Mission</div>
          <div class="h-0.5 hidden md:flex md:w-96 bg-black mt-10 mb-4"></div>
        </div>
        <div
          class="
            md:px-20 md:w-2/3
            flex-shrink-0
            text-lg
            md:text-2xl
            mt-10
            md:mt-20
          "
        >
          <p>
            Our mission is to contribute to the environment while providing our
            clients with the best customised FM solutions and delivering
            excellence in our services and after-service support. \
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-gray bg-opacity-50">
    <div class="container mx-auto px-5 py-10 md:py-32" id="vision">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl font-medium">Vision</div>
          <div class="h-0.5 hidden md:flex w-96 bg-black mt-10 mb-4"></div>
        </div>
        <div
          class="
            md:px-20 md:w-2/3
            flex-shrink-0
            text-lg
            md:text-2xl
            mt-10
            md:mt-20
          "
        >
          <p>
            To provide the best customer services in the integrated facility
            management space and create a convenient and technologically
            advanced ambience incorporating sustainable solutions.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-white" id="value">
    <div class="container mx-auto px-5 py-10 md:py-32">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl font-medium">Our Values</div>
          <div class="h-2 md:w-16 bg-brandBlue mt-5 mb-4"></div>
        </div>
      </div>
      <div class="md:px-16">
        <div
          class="
            grid grid-cols-1
            sm:grid-cols-1
            md:grid-cols-2
            xl:grid-cols-2
            mt-10
          "
        >
          <div
            v-for="(data, index) in values"
            :key="index"
            class="flex flex-col md:px-28 py-5"
          >
            <div class="flex-1">
              <div class="flex items-center">
                <h3 class="flex flex-col font-bold text-brandBlue text-xl mr-4">
                  0{{ index+1 }}
                </h3>
                <h3 class="font-medium text-2xl">{{ data.title }}</h3>
              </div>
              <p class="text-gray-500 text-left py-10">
                {{ data.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-b border-gray10 w-5/6 mx-auto opacity-50"></div>
  </section>

  <!-- <section class="bg-white overflow-hidden" id="leadership">
    <div class="container mx-auto md:px-16 py-10 md:py-32">
      <div class="flex flex-col text-center md:text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl">Our Leadership Team</div>
          <div class="h-2 md:w-16 bg-brandBlue mt-5 mb-12"></div>
        </div>
      </div>
      <div>
        <div class="flex justify-center flex-col items-center mt-6 md:px-40">
          <div class="flex bg-opacity-20 mb-16 bg-gray10 flex-col md:flex-row">
            <div class="md:w-96 md:h-96">
              <img
                class="object-cover h-full"
                src="@/assets/images/avatar/user1.webp"
              />
            </div>
            <div
              class="
                w-96
                flex flex-col
                justify-center
                px-12
                pb-10
                md:pb-0
                pt-5
                md:pt-0
              "
            >
              <div class="flex items-center">
                <h3 class="mt-4 text-2xl">Chad Gilbert</h3>
              </div>
              <p class="text-gray-500 mt-5">
                We intend to work together as a team and provide organizations
                with quality services. At Maven we are committed to helping you
                reach your goals.
              </p>
            </div>
          </div>
          <div class="flex bg-opacity-20 mb-16 bg-gray10 flex-col md:flex-row">
            <div
              class="
                w-96
                flex flex-col
                justify-center
                px-12
                pb-10
                md:pb-0
                pt-5
                md:pt-0
              "
            >
              <div class="flex items-center">
                <h3 class="mt-4 text-2xl">Teamwork</h3>
              </div>
              <p class="text-gray-500 mt-5">
                We intend to work together as a team and provide organizations
                with quality services. At Maven we are committed to helping you
                reach your goals.
              </p>
            </div>
            <div class="md:w-96 md:h-96">
              <img
                class="object-cover h-full"
                src="@/assets/images/avatar/user2.webp"
              />
            </div>
          </div>
          <div class="flex bg-opacity-20 mb-16 bg-gray10 flex-col md:flex-row">
            <div class="md:w-96 md:h-96">
              <img
                class="object-cover h-full"
                src="@/assets/images/avatar/user3.webp"
              />
            </div>
            <div
              class="
                w-96
                flex flex-col
                justify-center
                px-12
                pb-10
                md:pb-0
                pt-5
                md:pt-0
              "
            >
              <div class="flex items-center">
                <h3 class="mt-4 text-2xl">Chad Gilbert</h3>
              </div>
              <p class="text-gray-500 mt-5">
                We intend to work together as a team and provide organizations
                with quality services. At Maven we are committed to helping you
                reach your goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-b border-gray10 w-5/6 mx-auto opacity-50"></div>
  </section> -->

  <section class="bg-white" id="accreditations">
    <div class="container mx-auto px-6 md:px-16 py-10 md:py-32">
      <div class="flex flex-col text-left md:flex-row">
        <div class="flex flex-col items-left md:w-1/2">
          <div class="text-2xl md:text-5xl font-medium">Our Accreditations</div>
          <div class="h-2 md:w-16 bg-brandBlue mt-5 mb-4"></div>
        </div>
      </div>
      <div class="flex bg-opacity-20 my-10 flex-col md:flex-row">
        <div
          class="flex-1 md:w-32 border border-gray10 border-opacity-20 py-10"
        >
          <div class="items-center">
            <div class="text-center px-20 assets">
              <img class="mx-auto" src="@/assets/images/QA-Technic-Logo.png" />
            </div>
            <div class="px-20 text-center">
              <div class="items-center">
                <h2 class="mt-4 font-bold text-lg">ISO 9001: 2015</h2>
              </div>
              <p class="text-gray-500 text-sm mt-2">
                Quality Management System
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex-1 md:w-32 border border-gray10 border-opacity-20 py-10"
        >
          <div class="items-center">
            <div class="text-center px-20 assets">
              <img class="mx-auto" src="@/assets/images/logo2.png" />
            </div>
            <div class="px-20 text-center">
              <div class="items-center">
                <h2 class="mt-4 font-bold text-lg">ISO 9001: 2015</h2>
              </div>
              <p class="text-gray-500 text-sm mt-2">
                Quality Management System
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex-1 md:w-32 border border-gray10 border-opacity-20 py-10"
        >
          <div class="items-center">
            <div class="text-center px-20 assets">
              <img class="mx-auto" src="@/assets/images/logo2.png" />
            </div>
            <div class="px-20 text-center">
              <div class="items-center">
                <h2 class="mt-4 font-bold text-lg">ISO 9001: 2015</h2>
              </div>
              <p class="text-gray-500 text-sm mt-2">
                Quality Management System
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      ourEdge: [
        {
          title: "Always Equipped",
          description:
            "We are always up-to-date with the current trends and best practices in the Facility Management space.",
        },
        {
          title: "Fully Compliant",
          description:
            "We strictly adhere to statutory compliance norms without compromising the quality of our services.",
        },
        {
          title: "Trained Workforce",
          description:
            "Our employees/staff go through rigorous training before they are assigned to any project.",
        },
        {
          title: "Sustainable Approach",
          description:
            "We believe in a better tomorrow therefore, we use sustainable technology, tools & equipment in the delivery of soft services.",
        },
        {
          title: "Customized Solutions",
          description:
            "We offer tailored and innovative solutions to reach beyond your expectations.",
        },
      ],
      values: [
        {
          title: "Integrity",
          description:
            "We take pride in serving our clients with honesty and quality performance. Maven aims to be the most reliable and trustworthy organisation for both employees and clients.",
        },
        {
          title: "Diversity",
          description:
            "Our code of conduct consciously employs a workforce, concerning the equality of Gender and Age. We promote diversity and equality in all terms.",
        },
        {
          title: "Always Responsive",
          description:
            "We believe in delivering great customer service. Client satisfaction gives us immense pleasure to continue providing the best services with enthusiasm and improvement.",
        },
        {
          title: "Teamwork",
          description:
            "We intend to work together as a team and provide organizations with quality services. At Maven we are committed to helping you reach your goals.",
        },
        {
          title: "Sustainability",
          description:
            "We care for our generations to come, therefore we focus on providing economic, nature-friendly solutions.",
        },
        {
          title: "Continuous Improvement",
          description:
            "We organize regular training sessions for our employees to stay updated with the latest technology and skill upgrades.",
        },
      ],
    };
  },
};
</script>

<style>
</style>
